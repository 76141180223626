export const rootFields = [
  'accountList',
  'accountListCoaches',
  'accountListInvites',
  'accountListPledges',
  'accountListUsers',
  'accountLists',
  'address',
  'addresses',
  'announcements',
  'appeal',
  'appealContacts',
  'appeals',
  'coachingAccountList',
  'coachingAccountListPledges',
  'coachingAccountLists',
  'constant',
  'contact',
  'contactDuplicates',
  'contacts',
  'donation',
  'donations',
  'financialAccount',
  'financialAccounts',
  'notificationPreferences',
  'notificationTypes',
  'organizations',
  'people',
  'person',
  'personDuplicates',
  'reportsActivityResults',
  'reportsDonationHistories',
  'reportsPeopleWithAnniversaries',
  'reportsPeopleWithBirthdays',
  'reportsTagHistories',
  'task',
  'tasks',
  'user',
  'userNotifications',
  'userOptions',
  'userOrganizationAccounts',
  'acknowledgeAllUserNotifications',
  'acknowledgeAnnouncement',
  'acknowledgeUserNotification',
  'anonymizeContact',
  'apiOauthSignIn',
  'assignContactsToAppeal',
  'cancelAccountListInvite',
  'createAccountListInvite',
  'createAccountListPledge',
  'createAddress',
  'createAppeal',
  'createContact',
  'createDonation',
  'createOrUpdateUserOption',
  'createOrganizationAccount',
  'createPerson',
  'createTask',
  'createTaskComment',
  'createTasks',
  'deleteAccountListCoach',
  'deleteAccountListPledge',
  'deleteAccountListUser',
  'deleteAddress',
  'deleteAppeal',
  'deleteAppealContact',
  'deleteContact',
  'deleteDonation',
  'deleteOrganizationAccount',
  'deletePerson',
  'deleteTask',
  'deleteTasks',
  'deleteUser',
  'deleteUserOption',
  'mergeAccountList',
  'mergePeople',
  'oktaSignIn',
  'resetAccountList',
  'syncOrganizationAccount',
  'updateAccountList',
  'updateAccountListPledge',
  'updateAddress',
  'updateAppeal',
  'updateContact',
  'updateContacts',
  'updateDonation',
  'updateDonations',
  'updateDuplicate',
  'updateNotificationPreferences',
  'updateOrganizationAccount',
  'updatePeople',
  'updatePerson',
  'updatePreference',
  'updateTask',
  'updateTasks',
  'updateUser',
  'userKeySignIn',
];
